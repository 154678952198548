import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/iOS/InstarVision/Wizard/iPhone/Install/IN-6001-8001/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "iphone-app-installationsassistent",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iphone-app-installationsassistent",
        "aria-label": "iphone app installationsassistent permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iPhone App Installationsassistent`}</h2>
    <h3 {...{
      "id": "hinzufügen-einer-neuen-ip-kamera-in-6001-hd--in-8001-fhd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hinzuf%C3%BCgen-einer-neuen-ip-kamera-in-6001-hd--in-8001-fhd",
        "aria-label": "hinzufügen einer neuen ip kamera in 6001 hd  in 8001 fhd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen einer neuen IP Kamera (`}<em parentName="h3">{`IN-6001 HD & IN-8001 FHD`}</em>{`)`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a3329149bf559487a4abe81632be002c/81315/iPhone_Wizard_Installation_8001_6001_d01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAADK0lEQVQ4y41UW2/jRBT2P+GRB14QL/xGHhACJP7NSstKW9g0W1K1iK22SZs4aXyNZzxX2zP+0BzHiYOKwNKnmTlz/M25R1IqlEUJ73q0rYf3ILSdh3M98rwE5wLWtsjSDF036PQ9TroBjNXIiwOihHXYFC2Eduh9jyTJCF3Xo2sdhGrxUnXIuENcNmhajywrsFw+YbPZYr2O6TFjO2zKFhHjBhW34LWBEAZVJVFVgvY1yYa7oMfrhuRpWuLlJcNul9LKmIRSDelFda0xgnNNJAFhP6I+yquDQJ4zxHGCzWaP7TY5kWZZRbpRYOVHkinR9BysTNMDrq//IMxmN7i5ucNicY+PH2/pPJ8vsFrFwcIz2WsYCfO8wnx+SyS3t3/i/fsPuLqa4fp6gbu7v0j++fPzmXDq4oW7/3hAiIbiHFzdblOyPDw43kfnny9dfy0E072UDYGSNpFHIWtaWRT5AbvdEOQ43hOG1w1ErS/crycWjcTjfcS1w551yOpQaw26roNzDs55gvceuvGnMsqyA5KkoJiGzD48rLBabc4uC2WQFAwlk8gOHEIIIglfH9oBPRrXQ8jBteDBp7jGLqnw8OkRb968xbt3V1ROwfpIWo9SeQjrgN6Tdd47Ig2EAUR4jFWw8HlbIEkrLB+fMLuPsbhfIk0KSljEaoWXnCErORjjqCoGxhiklPC+J2JDLttT4LO0PCViHWfY78uzy1IYKBmCO7TeFOdSGTNuwJhGUQpaeW1xOEhUTFGFDEn5l/p7DSHbrdbojKG1VRqdNui0hhJD6f1np4yWBbKyMpg/Wfy+tJitBnxYWvz2aLFODaTQ/48wTJpGaSzWFl//5PHl9x7f/Ozx7S8eX/3g8cV3Hj/+2gJWj9NmSjBOn4mFR3kgfk4NVsmA5f64Jgb5YfAiopbj5jSmpudLDORaapgJxrM4jr9oahE/zUV1keEhy8e7cdhyDTZmN+ge9aK6rmGURKF6lBpojIZSGm3bQmsNaxtYa6GUghQ1Kt0jk0DXWgghqRFCzbZtRzqRO3aF8z0Vdp7nKIocnDOS051zp73ve/BaoCC9AlVVne7D+je9V4jZIvQdywAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a3329149bf559487a4abe81632be002c/e4706/iPhone_Wizard_Installation_8001_6001_d01.avif 230w", "/en/static/a3329149bf559487a4abe81632be002c/d1af7/iPhone_Wizard_Installation_8001_6001_d01.avif 460w", "/en/static/a3329149bf559487a4abe81632be002c/7f308/iPhone_Wizard_Installation_8001_6001_d01.avif 920w", "/en/static/a3329149bf559487a4abe81632be002c/e1c99/iPhone_Wizard_Installation_8001_6001_d01.avif 1380w", "/en/static/a3329149bf559487a4abe81632be002c/6e1e4/iPhone_Wizard_Installation_8001_6001_d01.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a3329149bf559487a4abe81632be002c/a0b58/iPhone_Wizard_Installation_8001_6001_d01.webp 230w", "/en/static/a3329149bf559487a4abe81632be002c/bc10c/iPhone_Wizard_Installation_8001_6001_d01.webp 460w", "/en/static/a3329149bf559487a4abe81632be002c/966d8/iPhone_Wizard_Installation_8001_6001_d01.webp 920w", "/en/static/a3329149bf559487a4abe81632be002c/445df/iPhone_Wizard_Installation_8001_6001_d01.webp 1380w", "/en/static/a3329149bf559487a4abe81632be002c/87a8c/iPhone_Wizard_Installation_8001_6001_d01.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a3329149bf559487a4abe81632be002c/81c8e/iPhone_Wizard_Installation_8001_6001_d01.png 230w", "/en/static/a3329149bf559487a4abe81632be002c/08a84/iPhone_Wizard_Installation_8001_6001_d01.png 460w", "/en/static/a3329149bf559487a4abe81632be002c/c0255/iPhone_Wizard_Installation_8001_6001_d01.png 920w", "/en/static/a3329149bf559487a4abe81632be002c/b1001/iPhone_Wizard_Installation_8001_6001_d01.png 1380w", "/en/static/a3329149bf559487a4abe81632be002c/81315/iPhone_Wizard_Installation_8001_6001_d01.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a3329149bf559487a4abe81632be002c/c0255/iPhone_Wizard_Installation_8001_6001_d01.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Beginnen Sie mit dem Menü `}<strong parentName="li">{`Kameras`}</strong>{` und wählen Sie den `}<strong parentName="li">{`Installationsassistenten`}</strong>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` Sie finden den `}<strong parentName="li">{`QR-Code`}</strong>{` in der `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/Internet_Access/"
        }}>{`WebUI`}</a>{` (A), auf dem Etikett Ihrer Kamera (B) oder auf der Verpackung abgedruckt.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/81315/iPhone_Wizard_Installation_8001_6001_d02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAACNklEQVQ4y52Vyc8hURTFqwwfhZiHGBaWFv7/bS97owwLWyEhQSuzoqhyOud2V7WhKfkkJ56XvN87d3iXoqhBKKEoFEV5KU2LQW+1sFoaOBwOOJ1OOJ/PIsuyRIZhYDabQVFCEajxLBQ18BIY0zT8+NnD0gSs0xHr9QaDwUA0mUwEzIso5Z0zVVX/AGMaWnobC2MF0zzAtm0cj0dxSneO43h6C/QcxmLotNtYGoYAHj/X69WTB0yn0ygWi6J8Po9CoYBsNvs3hxq63S42mw3m8zkul4scpqNbmAdMJBIiOonH43fiHoG9Xg/r9Rr7/f4J8gTkwXfhSsidjgApXyDdBQIBrxC3coFt5nC5xGq1+m+oT0C3oq8cErhYLKRlfIE8QIeZTEaUy+VEvCQajd6FTJdsm7dAVpguK5UKSqWSqFarSW6TyaQAWeXtdiu999gqT8B6vS4wfjebTTQaDW+vXC57bWOapjyxj6sciUQkZPYhQ33M4W63k8b2BfJAKpWSsAmiuOYe13ToVnk6nfrn8PaluL+5ZmHcCwlky3yUQ4puboGEcc97y52OhMyn5+uQITFEVrdarYpYDAKZV7fKBHLK+Dr8+vqSgxRz5q55USgU8kJm23D2+Rblk/Gl67q0jDu+vgV0n6Pbh5zI2+0OF9uWPHKM8ftbA7al67DPFuYbCwsTuDoXHExT3PIiwtn4d8BgMIhwOCzi+t+flIZ+vy+HbcfB/JeB4XCI0WiE8XgseaU7uv0NFvBA6jtC+EUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/e4706/iPhone_Wizard_Installation_8001_6001_d02.avif 230w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/d1af7/iPhone_Wizard_Installation_8001_6001_d02.avif 460w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/7f308/iPhone_Wizard_Installation_8001_6001_d02.avif 920w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/e1c99/iPhone_Wizard_Installation_8001_6001_d02.avif 1380w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/6e1e4/iPhone_Wizard_Installation_8001_6001_d02.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/a0b58/iPhone_Wizard_Installation_8001_6001_d02.webp 230w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/bc10c/iPhone_Wizard_Installation_8001_6001_d02.webp 460w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/966d8/iPhone_Wizard_Installation_8001_6001_d02.webp 920w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/445df/iPhone_Wizard_Installation_8001_6001_d02.webp 1380w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/87a8c/iPhone_Wizard_Installation_8001_6001_d02.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/81c8e/iPhone_Wizard_Installation_8001_6001_d02.png 230w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/08a84/iPhone_Wizard_Installation_8001_6001_d02.png 460w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/c0255/iPhone_Wizard_Installation_8001_6001_d02.png 920w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/b1001/iPhone_Wizard_Installation_8001_6001_d02.png 1380w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/81315/iPhone_Wizard_Installation_8001_6001_d02.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/c0255/iPhone_Wizard_Installation_8001_6001_d02.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Verwenden Sie die App, um den QR-Code von Ihrer Kamera oder Ihrem Kamerapaket zu scannen. Wenn Ihre Kamera keinen QR-Code hat, wählen Sie bitte im nächsten Schritt das Kameramodell manuell aus.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Wählen Sie Ihr Kameramodell, z.B. `}<strong parentName="li">{`IN-8001FullHD`}</strong></li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ef23579b2b11041f56412feec17c0cbd/81315/iPhone_Wizard_Installation_8001_6001_d03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC4ElEQVQ4y31Uy67cNgz1PwXoIlnmB4umaRb5kiy6aIPsgyJpgCTInYdn/JStt2RZ1gkk+3o8c6c1cGCJIg9JiWQmpQJpW4QpYBw9vA+YfIBz87ppCBiTGKxDXdUYx2k9myasa0o52rZDVvQjcjIg7z2ECWjqBlXVYBwDRuch1IBzP6JiPunZwSeHhPQ4HPLVgTEOB+KQUWYQ0dP41yiKBqdTBc41ODfgwoDxWYcym2RCGFAq0XU8rR9lUS+bDWdjxjSahibENWOz7KKjF/nibLG5yDSyyMq4WZWEsAmzweJog1sivpXFCB+Ft4dbw9tI/g/Z1ohShbru0bb0KoJbZzEDKYeESzYzVsJ4UFUd/vn4CcW5SeTbyM7nGsdjgcOhwMNDju/f9/j27QE/fhxwPJbpLM/LG8KS4LA/JcK+E5voNJR20GbEMHhIadD3HITETASsdZDKQqrhOuW66hJZVRH0vVwJpbQgzODUMEgpIKWE1hrDMMBai2GwOAugFHh6h028w4ZePUqMXimFrm1ASIuyLFHXNYQQUErDGgNqApgJ14Qd4ShLklInhF8IuYFxAXwArMfdj9sAYW8IY5qRiBD2JOWeCXx9yLHLC5RlgfyUo2kaCClhlMSuD9hTzJ0SO6KjM3pmEx73dHGmlIVWGkpGBzOiLPZwhNQuPVwWyYzUwHgHXqczuhauSbjXdmtha6nxJTf47W+H3987vPkw4/V7h1//cvh8NAupuerpdXhs2i4VttMaf36x+OX1hOd/THj5dsaLNxOevZrw7l+LUes0jbbR/RcuKbsFw4Jlb5VeiJ5G9XS/tB5NjxKjmCPp6bx/fJTt/aWKYHqN9s5wuHiejRUoU6ssDs55iF7PzajH7uhlnItUR60K6CwwDrEULLz3V+0VO0IKjl4HNBqY/JhkIYTURc6N6Z9N04QIPwUUZYXdbpdwPp8RwnLmPWY9jxCAuiVJZ7/fI89zOOdWvZ9++JEsUqgLQAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ef23579b2b11041f56412feec17c0cbd/e4706/iPhone_Wizard_Installation_8001_6001_d03.avif 230w", "/en/static/ef23579b2b11041f56412feec17c0cbd/d1af7/iPhone_Wizard_Installation_8001_6001_d03.avif 460w", "/en/static/ef23579b2b11041f56412feec17c0cbd/7f308/iPhone_Wizard_Installation_8001_6001_d03.avif 920w", "/en/static/ef23579b2b11041f56412feec17c0cbd/e1c99/iPhone_Wizard_Installation_8001_6001_d03.avif 1380w", "/en/static/ef23579b2b11041f56412feec17c0cbd/6e1e4/iPhone_Wizard_Installation_8001_6001_d03.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ef23579b2b11041f56412feec17c0cbd/a0b58/iPhone_Wizard_Installation_8001_6001_d03.webp 230w", "/en/static/ef23579b2b11041f56412feec17c0cbd/bc10c/iPhone_Wizard_Installation_8001_6001_d03.webp 460w", "/en/static/ef23579b2b11041f56412feec17c0cbd/966d8/iPhone_Wizard_Installation_8001_6001_d03.webp 920w", "/en/static/ef23579b2b11041f56412feec17c0cbd/445df/iPhone_Wizard_Installation_8001_6001_d03.webp 1380w", "/en/static/ef23579b2b11041f56412feec17c0cbd/87a8c/iPhone_Wizard_Installation_8001_6001_d03.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ef23579b2b11041f56412feec17c0cbd/81c8e/iPhone_Wizard_Installation_8001_6001_d03.png 230w", "/en/static/ef23579b2b11041f56412feec17c0cbd/08a84/iPhone_Wizard_Installation_8001_6001_d03.png 460w", "/en/static/ef23579b2b11041f56412feec17c0cbd/c0255/iPhone_Wizard_Installation_8001_6001_d03.png 920w", "/en/static/ef23579b2b11041f56412feec17c0cbd/b1001/iPhone_Wizard_Installation_8001_6001_d03.png 1380w", "/en/static/ef23579b2b11041f56412feec17c0cbd/81315/iPhone_Wizard_Installation_8001_6001_d03.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ef23579b2b11041f56412feec17c0cbd/c0255/iPhone_Wizard_Installation_8001_6001_d03.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Wählen Sie aus, ob Ihre Kamera über `}<strong parentName="li">{`WiFi`}</strong>{` angeschlossen werden soll oder ob Sie ein Ethernet-Kabel verwenden werden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1354160e7c38e577d422fd7f796f994c/81315/iPhone_Wizard_Installation_8001_6001_d04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAADcElEQVQ4y41VTY/cRBD1r+HAAa7wF7ghLiDxRzggRSLKDQSXHDmAiLgj5UPJNYo22U1md2cyWS0i8+kZf3Xbbbfbbbf9UJXt2VmUA4en7q56fvWq3Ja9otCI4xhN06KuHZzr0LbdYR9FCbKsgK1qhEH4Hx4O+zRViGMBbysb+MIykQhBEDGcA1zTQmkL4gSZwyaxsHWLrgOEyLBeb1mMeMbUWMU1vDQzyFSFOE4RRSn2+5iRJBmiSKIoLDLVc1JVMYfiu110i5dlJVRu4dEmDATW6x1Wqx2vm02A5XLLCEPBZCFyRKHEdhsyhwRpv90GzA8CASlzeJR4/foCs9kVLi/nODk5w9nZOU5PJzg/n+Hi4i18P4K/izj38uUbxqtXb/DixSnziP/8+Qmur9/3DpUyjDyvsNvFjDy3fB5z5HA+v2YBKjyZzDCZTLkoCVKMXHrjAyNoZoR+NinHxqJU4EM4zrPDbHgolQWkLDi53wXw/eAgeIw01UcilucshOLzoeU4zjC7nGM+u2IyOUwSIt04oLbf/7PCcrHBauVzB7PpO1xMpvzGDw5pQ2/6an6Nq3d/swMpciRxdqulvpBiIZEoLrBe+Vivdnw+OBwFKUlXhQSJnAyksdXjF0QdURd01mVz4B1aTtMS282eWyFnQZDccjau7F4WiKP+QwhDeauwpxRVN0ikhpAKUSwRhikSUUCmg9ggSELUCTkkkDg5HW+DGh3qwqDRCqWMYNIErdGANeiqEjrvC3IXWYkoVghCCSE16LOVaR+TUvczLPISq9Dg0cziydsBM4vHswoPpxWWgWFRephWVBqd6dGaErAUK2F1z/GassSjaYWPf2rxyc8tPr/f4rP7LT79pcVHP7b469KCOCQ23xp8/cDhy98cvnng8O2fDl/97vDFrw5/nFo4M8yQEAmDUBgEw0qg2DhjElwEBnef1vj+cY0fnta496zGnSc1vntY49m8YpdeNghS6x/CeFVGUdRHsMPalKjLoWUi9hg+q+GcHvbDHRziIi0hUsOQ6c2Zrp3Kjlq+ucCaMcbyvMf/5XlK5TC6QKw7iApobAVjKjjnUJYlrLWoqgpa0wgUZNkhKoHWNRzrug5aa9R1w6vXtvQvaeHaDrt9gMViwfB9H1035JxDz3P8PwnjhDnL5RKbzQZ1XR94/wJCJ3bGprxJ6wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1354160e7c38e577d422fd7f796f994c/e4706/iPhone_Wizard_Installation_8001_6001_d04.avif 230w", "/en/static/1354160e7c38e577d422fd7f796f994c/d1af7/iPhone_Wizard_Installation_8001_6001_d04.avif 460w", "/en/static/1354160e7c38e577d422fd7f796f994c/7f308/iPhone_Wizard_Installation_8001_6001_d04.avif 920w", "/en/static/1354160e7c38e577d422fd7f796f994c/e1c99/iPhone_Wizard_Installation_8001_6001_d04.avif 1380w", "/en/static/1354160e7c38e577d422fd7f796f994c/6e1e4/iPhone_Wizard_Installation_8001_6001_d04.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1354160e7c38e577d422fd7f796f994c/a0b58/iPhone_Wizard_Installation_8001_6001_d04.webp 230w", "/en/static/1354160e7c38e577d422fd7f796f994c/bc10c/iPhone_Wizard_Installation_8001_6001_d04.webp 460w", "/en/static/1354160e7c38e577d422fd7f796f994c/966d8/iPhone_Wizard_Installation_8001_6001_d04.webp 920w", "/en/static/1354160e7c38e577d422fd7f796f994c/445df/iPhone_Wizard_Installation_8001_6001_d04.webp 1380w", "/en/static/1354160e7c38e577d422fd7f796f994c/87a8c/iPhone_Wizard_Installation_8001_6001_d04.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1354160e7c38e577d422fd7f796f994c/81c8e/iPhone_Wizard_Installation_8001_6001_d04.png 230w", "/en/static/1354160e7c38e577d422fd7f796f994c/08a84/iPhone_Wizard_Installation_8001_6001_d04.png 460w", "/en/static/1354160e7c38e577d422fd7f796f994c/c0255/iPhone_Wizard_Installation_8001_6001_d04.png 920w", "/en/static/1354160e7c38e577d422fd7f796f994c/b1001/iPhone_Wizard_Installation_8001_6001_d04.png 1380w", "/en/static/1354160e7c38e577d422fd7f796f994c/81315/iPhone_Wizard_Installation_8001_6001_d04.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1354160e7c38e577d422fd7f796f994c/c0255/iPhone_Wizard_Installation_8001_6001_d04.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/430beb81f46015e75a923596cd6bbd13/81315/iPhone_Wizard_Installation_8001_6001_d05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAADIklEQVQ4y5WVyY4cRRCG6wERR7jCc8AFTvAUCAkufgFLPlhIyIe2TXsW3JZnLE+v1bXvWZm1flZkd/W0pcEaQvqVa/35R2RGlFNViiiM6LqBtu3p+5FhGE/9MIzJ84q6rtm7O4wxaK1pmoa2bU/9OI7xfR9nl3S4aWsJhwF8P8TzAvoe+n6gVA2yx0sNq6Ci1oau6wiCgPV6bQ9om4ZKKZZhjZMXhiSrCcPUwnUDfD8mjnOCIKEoNEXZ0HQ9I2CahrIsyfOcLMtsX9QPw0A/jDhpUuJ7MauVy93dht0uYLv1LdbrPZ4XkWUKYxqqsqQsCqqqshCiqbWEfY/jeTGvX1/w8uWc2WzOq1dvmM3+seP5/IoXL2b4fkKSpCwWCzabDZON43hqx3Gg0h2OnH57e8d8fs3V1YLr63dcXh7ai4t/ubn5aBXWtT65KYokjnIpE2RsmhZHYlSWxsaqqhoLGZ9DqYYoiqzC29tblFJWmbh5UHdAZTqcPK8RCGEUZvZCkqS04/O1rutP7kmsRJHMHRR2lnyQGE4fiFvv3r7n44cVSZxTFLXFtC5PSKzvWtIkJokjijwlCn1UVR4P40AobsVxwWbtstmGLO4SPuw1YaIpiwOhKOgGyPVIbkZiNdp+YbBQrVzQeK9Q3Nxu9uy9mJ2XkeXisqbI7wlFpJBVDScigRDXLZ8TShtFGWGQ4nshWVad5s9dhvE/cIjvZ4SiUjJE3D+fPyc83CgPQswp7E1qklThupF1eb+P7TjNNdkDhF8yR8h0VUMj0AxaMxgN5jAna/kxho8ibFTN3zeGr3/v+eaPnu+f9Hz3pOfbP3u++q3nr/eGrtZ0j1UoCi7Xhh+ftvz0rOXX5y2/PG/5+VnLD09b3iwNRv0PwpPL7RHNEdLvHnb5S7C5LG8uye6R2ra2/ezsHT7GnCnF7nNXWchcWeoTJG9HyddhpD/WPoHN4bMi4RRFiVYVkRpJDHSN/DOM3SxlSv4XUuZVXaOqgrQeiWoYh87OiUn1kWIh+53pBCnfe89nuVxauK5ri+ZUiadW7iQIY7tntVqx3W5txZkq9icGeJmuodj+ZAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/430beb81f46015e75a923596cd6bbd13/e4706/iPhone_Wizard_Installation_8001_6001_d05.avif 230w", "/en/static/430beb81f46015e75a923596cd6bbd13/d1af7/iPhone_Wizard_Installation_8001_6001_d05.avif 460w", "/en/static/430beb81f46015e75a923596cd6bbd13/7f308/iPhone_Wizard_Installation_8001_6001_d05.avif 920w", "/en/static/430beb81f46015e75a923596cd6bbd13/e1c99/iPhone_Wizard_Installation_8001_6001_d05.avif 1380w", "/en/static/430beb81f46015e75a923596cd6bbd13/6e1e4/iPhone_Wizard_Installation_8001_6001_d05.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/430beb81f46015e75a923596cd6bbd13/a0b58/iPhone_Wizard_Installation_8001_6001_d05.webp 230w", "/en/static/430beb81f46015e75a923596cd6bbd13/bc10c/iPhone_Wizard_Installation_8001_6001_d05.webp 460w", "/en/static/430beb81f46015e75a923596cd6bbd13/966d8/iPhone_Wizard_Installation_8001_6001_d05.webp 920w", "/en/static/430beb81f46015e75a923596cd6bbd13/445df/iPhone_Wizard_Installation_8001_6001_d05.webp 1380w", "/en/static/430beb81f46015e75a923596cd6bbd13/87a8c/iPhone_Wizard_Installation_8001_6001_d05.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/430beb81f46015e75a923596cd6bbd13/81c8e/iPhone_Wizard_Installation_8001_6001_d05.png 230w", "/en/static/430beb81f46015e75a923596cd6bbd13/08a84/iPhone_Wizard_Installation_8001_6001_d05.png 460w", "/en/static/430beb81f46015e75a923596cd6bbd13/c0255/iPhone_Wizard_Installation_8001_6001_d05.png 920w", "/en/static/430beb81f46015e75a923596cd6bbd13/b1001/iPhone_Wizard_Installation_8001_6001_d05.png 1380w", "/en/static/430beb81f46015e75a923596cd6bbd13/81315/iPhone_Wizard_Installation_8001_6001_d05.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/430beb81f46015e75a923596cd6bbd13/c0255/iPhone_Wizard_Installation_8001_6001_d05.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[09]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
      <li parentName="ul"><strong parentName="li">{`[10]`}</strong>{` Sobald Ihre Kamera angeschlossen ist, klicken Sie auf `}<strong parentName="li">{`Suchen`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie die Option `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/06294e81e4d22fff6314b08a26914457/81315/iPhone_Wizard_Installation_8001_6001_d06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAClUlEQVQ4y61Vy27TQBT1ByGxQayAJQsWIL6LBSpSkcoCIdRFVTb8ARIPCSQ2tGLTNmkCIY8mfsT2zPg1c9C5jl03SmkWXOlo7Dtnztx7PXfsLZcRLmZT5FkGYwzyPBc0z9PpFEEYQiuFyfgPssy0c0VRtM++72M2m8H77Rv8mitobVCWJSaTCcbjsZAzYxDFCiPfYBJmGF4oaJMJb7FYYDgcCo+CqVIYzDW8orQoLcS4WxzHWC6X8kxzzqGsrKByNUdrjTRNEUVR+05eZR08LmIaJIRhKGIcSVZKoSjyWthaGKPFHwSBcJqRoKitqkYwEyLT7ff7ODk5wXw+FyJFG+M753u9Hs7OznB6eiqgbzQaSSk8LuBOfGlS3GSMgNFz44a7yTxGQlRVJWJd0EfQoijEYDBA/3wIZXJUFigq1tchKyqpMdd4LCpT5q7rgl1YawFHcUbn1mBltPwo/KqMkJ/+upTtyjWMgCf7wMPXwOO3wNN94NEb4MEr4ODHKmUKsY7XpcxzZl0dSaAddr86PP9cj3vfHHa+ODz76PB9VHM8piqLrG0j6op2fTcZM5EaJkki5zBJ0vZA07hREIRS4/aQWyeNQFSrkWjK4pGcpgpJqhBTMMtlkijKSuYofDVybIQIbg6++Xpd180prwQdPp073H7hcGfX4f6ew7094O5L4NaOw4deLc70tqmjRDhNgMMj4PAYeP+zxrtj4OAIGMeXAW4TpLcNactsL1Nurp5NcKsz+K8u6sLDfzaPB7oGO8O2FwJ9673c8uxVXpfr8VAbncJXDmEGVEUGk2VC4pXV/GOU1lBpjFA7LDQv3FJ8NLYuO458r9mF9ZpMZ3JFEfyv2LVI6n4HLua+cPhP4cXatC7n/wJCX6hkfWBSnwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/06294e81e4d22fff6314b08a26914457/e4706/iPhone_Wizard_Installation_8001_6001_d06.avif 230w", "/en/static/06294e81e4d22fff6314b08a26914457/d1af7/iPhone_Wizard_Installation_8001_6001_d06.avif 460w", "/en/static/06294e81e4d22fff6314b08a26914457/7f308/iPhone_Wizard_Installation_8001_6001_d06.avif 920w", "/en/static/06294e81e4d22fff6314b08a26914457/e1c99/iPhone_Wizard_Installation_8001_6001_d06.avif 1380w", "/en/static/06294e81e4d22fff6314b08a26914457/6e1e4/iPhone_Wizard_Installation_8001_6001_d06.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/06294e81e4d22fff6314b08a26914457/a0b58/iPhone_Wizard_Installation_8001_6001_d06.webp 230w", "/en/static/06294e81e4d22fff6314b08a26914457/bc10c/iPhone_Wizard_Installation_8001_6001_d06.webp 460w", "/en/static/06294e81e4d22fff6314b08a26914457/966d8/iPhone_Wizard_Installation_8001_6001_d06.webp 920w", "/en/static/06294e81e4d22fff6314b08a26914457/445df/iPhone_Wizard_Installation_8001_6001_d06.webp 1380w", "/en/static/06294e81e4d22fff6314b08a26914457/87a8c/iPhone_Wizard_Installation_8001_6001_d06.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/06294e81e4d22fff6314b08a26914457/81c8e/iPhone_Wizard_Installation_8001_6001_d06.png 230w", "/en/static/06294e81e4d22fff6314b08a26914457/08a84/iPhone_Wizard_Installation_8001_6001_d06.png 460w", "/en/static/06294e81e4d22fff6314b08a26914457/c0255/iPhone_Wizard_Installation_8001_6001_d06.png 920w", "/en/static/06294e81e4d22fff6314b08a26914457/b1001/iPhone_Wizard_Installation_8001_6001_d06.png 1380w", "/en/static/06294e81e4d22fff6314b08a26914457/81315/iPhone_Wizard_Installation_8001_6001_d06.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/06294e81e4d22fff6314b08a26914457/c0255/iPhone_Wizard_Installation_8001_6001_d06.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[11]`}</strong>{` Sie werden nun aufgefordert, den Admin-Login Ihrer Kamera einzugeben. Wenn Sie es vorher nicht eingerichtet haben, wird dies der Standard-Userermane `}<strong parentName="li">{`admin`}</strong>{` und das Passwort `}<strong parentName="li">{`instar`}</strong>{` sein. Dies kann später innerhalb der App geändert werden.`}</li>
      <li parentName="ul"><strong parentName="li">{`[12]`}</strong>{` Bitte wählen Sie einen Namen für Ihre Kamera.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "828px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bbba476d1643d0445738abcc10269014/8efc2/iPhone_Wizard_Installation_8001_6001_d07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAArCAYAAAB4pah1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAD+UlEQVRIx+1XzW7jVBj1O8ALILEBhhdAvAbPMStmtgjNCiFmdrCfl0BoumgXRcBiaJsmceI0TZM0cRL/xfa9/rs+6HyOm0zpsEBskHqlk3u/v3M/27nOiVVVNbbbBN2ejcXCRV0DRVGhLI2A6zwvBXv/Pp6mGoPBEDfTudhWbWroVGE6GcP3PBizJ2zndnDz+5spleFmMoW7WKKqDCwvMbjygNsY8BXe2Z0EWmew7QFse4gkSWXDNt7mcLy9BfzUwPLjEo5bYrAsMPUKFHmJjMgK6SBJFHo9G+fnHQRBJCSM5ZJTIs8KKF3gYl4gSEpY8VZBJQqZyqASje1WI4rU3RzHGbSukGUkz8Qfx4yld2vOWaqRxBrWbObi4qKPi46N4fAaw+EYnU5fZtu+Qq83hG2PZN3tDtHp2JhM5uj1HIk5zgT9voPLywFubhawEukqQRynUCoXRFEi2G5ThGGMOFayps/3I1nz6bKG6zaPs1VVFSaTCbrdLs7Pz+E4Qxizf7IPjX6/D9u2cXZ2htFohMvLS0RRJDGrrmuQNMsypGmKLMslQNKHwPyy5HeSDyaXWq45M27x4/r6GuPxGPP5HIPBQDrmYPEhOLTWcBxHctgd16xnM0yRDsMwRBAE4lytVthsNkLwUHfsar1eS43v+3e19BtTN4Sz2QyLxQLL5VIu533dtTPH7e2tXBGvjBuwGbmH/HDdlZAxgTu19/A+ads1Z3bleZ7UsUvW0S+EvES2zyR3tUaiNEwNlFWN0vwdlamx8Xx4PvNXiONYHqoQckfeXLZ+dTWCOxsDRbK7MPMe1NgsJljNxxjafcxmcyRJ0hDy6PBYEUpVeDs1+PWqwKmT4XSUC44HDU6dxmbs94nBb9cGYWKQ6wJJkssxtHheN3zNZCm++TmH9bSG9byG9QwNvgY+fAF88AKwnmPvZ87TGl+9LlCkKYJQydm3eLCDUMNohdd/5Pj4e4Mnrww+e9ngkx8MvvjR4MufDJ68NPh05//8lcFH3xl8+0uBQimEEV8YmoQ8p0qMPG0Ca19jE+yx9Boc+taBhhc0NW09ZyvaMdMId3N8D0ncIH4gFh7U7zp81/FvsO9wR/hf4pHwkfCR8JHw/0zIH5dWZLYv3DDSB7Y6EKH/bFutSuXPYBtIYwWdqDubyvUw3ijX7IFGVKNtgiAUkSTSxZRwoxLOupI1pWIrOVrpl6Yk2oosoU1FRp/8C2ASC1zX3YmhGs6mxp+3zZpFLWGja4yoBOqZRpjWYlOOiHKg2uIOFDsiHE2NUBlEmjqGfyEK0S0E4yRhPsFa2kopAbu19hKtVVeVWPPpDY6OjnB8fHyHk5MTvHnzRiTxocptBzv8C9b6yHtBCSg6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bbba476d1643d0445738abcc10269014/e4706/iPhone_Wizard_Installation_8001_6001_d07.avif 230w", "/en/static/bbba476d1643d0445738abcc10269014/d1af7/iPhone_Wizard_Installation_8001_6001_d07.avif 460w", "/en/static/bbba476d1643d0445738abcc10269014/ec170/iPhone_Wizard_Installation_8001_6001_d07.avif 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bbba476d1643d0445738abcc10269014/a0b58/iPhone_Wizard_Installation_8001_6001_d07.webp 230w", "/en/static/bbba476d1643d0445738abcc10269014/bc10c/iPhone_Wizard_Installation_8001_6001_d07.webp 460w", "/en/static/bbba476d1643d0445738abcc10269014/712de/iPhone_Wizard_Installation_8001_6001_d07.webp 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bbba476d1643d0445738abcc10269014/81c8e/iPhone_Wizard_Installation_8001_6001_d07.png 230w", "/en/static/bbba476d1643d0445738abcc10269014/08a84/iPhone_Wizard_Installation_8001_6001_d07.png 460w", "/en/static/bbba476d1643d0445738abcc10269014/8efc2/iPhone_Wizard_Installation_8001_6001_d07.png 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bbba476d1643d0445738abcc10269014/8efc2/iPhone_Wizard_Installation_8001_6001_d07.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[14]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      